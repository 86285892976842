<template>
  <div>
    <div class="d-flex flex-row-reverse">
      <CButton color="primary" @click="modal = true" class="float-righ">
        Add</CButton
      >
    </div>

    <CRow>
      <CCol
        sm="12"
        md="6"
        lg="4"
        style="margin: auto; width: fit-content"
        v-for="interest in interests"
        :key="interest.id"
      >
        <CCardBody class="interest-card" @click="openModal(interest)">
          <strong> Label: </strong>{{ interest.label }} <br />
          <strong>Label Arabic: </strong> {{ interest.label_ar }} <br />
          <strong>Description: </strong> {{ interest.description }} <br />
        </CCardBody>
      </CCol>
    </CRow>

    <CModal
      :visible="modal"
      @close="
        () => {
          modal = false;
          v$.data.$model = { ...initData };
          v$.$reset();
          update = false;
          currentInterest = null;
        }
      "
    >
      <CModalHeader style="background-color: #fafafa"> </CModalHeader>
      <CModalBody style="background-color: #fafafa">
        <div
          class="form-group mb-3"
          :class="{ error: v$.data.label.$errors.length }"
        >
          <input
            class="form-control"
            placeholder="Label"
            type="text"
            v-model="v$.data.label.$model"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.data.label.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div
          class="form-group mb-3"
          :class="{ error: v$.data.label_ar.$errors.length }"
        >
          <input
            class="form-control"
            placeholder="Label Arabic"
            type="text"
            v-model="v$.data.label_ar.$model"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.data.label_ar.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div
          class="form-group mb-3"
          :class="{ error: v$.data.description.$errors.length }"
        >
          <input
            class="form-control"
            placeholder="Description"
            type="text"
            v-model="v$.data.description.$model"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.data.description.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
      </CModalBody>
      <CModalFooter style="background-color: #fafafa">
        <CButton color="danger" v-if="update" @click="Delete">Delete</CButton>
        <CButton color="primary" @click="actionInterest"
          ><span v-if="update">Update</span> <span v-else>Add</span></CButton
        >
      </CModalFooter>
    </CModal>
  </div>
</template>

<script>
import {
  InterestsPost,
  ListInterests,
  InterestsDelete,
  InterestsUpdate,
} from "@/services/apis/interests.js";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  data() {
    return {
      update: false,
      modal: false,
      currentInterest: null,
      interests: [],
      data: {
        label: null,
        label_ar: null,
        description: null,
      },
      initData: {
        label: null,
        label_ar: null,
        description: null,
      },
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      data: {
        label: {
          required,
        },
        label_ar: {
          required,
        },
        description: {},
      },
    };
  },
  created() {
    this.loadAllInterest();
  },
  methods: {
    loadAllInterest() {
      ListInterests().then((res) => {
        console.log(res.data);
        this.interests = res.data;
      });
    },
    openModal(interest) {
      this.update = true;
      this.currentInterest = interest.id;
      this.v$.data.$model = { ...interest };
      this.modal = true;
    },
    addInterest(data) {
      InterestsPost(data)
        .then((res) => {
          this.v$.$reset();
          this.v$.data.$model = { ...this.initData };
          this.loadAllInterest();
          alert("Interest added successfully");
        })
        .catch(() => {
          this.$Swal.fire("Interest adding", "Failed!.", "danger");
        });
    },
    actionInterest() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.data.label.toUpperCase();
        this.currentInterest
          ? this.Update(this.currentInterest, this.data)
          : this.addInterest(this.data);
      }
    },
    Delete() {
      if (this.currentInterest) {
        InterestsDelete(this.currentInterest)
          .then((res) => {
            this.loadAllInterest();
            this.modal = false;
            this.$Swal.fire("Deletion", "Success.", "success");
          })
          .catch(() => {
            this.$Swal.fire("Deletion", "Failed!", "danger");
          });
      }
    },
    Update(id, data) {
      InterestsUpdate(id, data)
        .then((res) => {
          this.loadAllInterest();
          this.modal = false;
          this.$Swal.fire("Update", "Success.", "success");
        })
        .catch(() => {
          this.$Swal.fire("Update", "Failed!", "danger");
        });
    },
  },
};
</script>

<style>
.interest-card {
  margin: 12px;
  border-radius: 12px;
  width: 260px;
  font-size: 14px;
  box-shadow: 1px 2px 28px -13px rgb(163 157 157 / 76%);
  -webkit-box-shadow: 1px 2px 28px -13px rgb(163 157 157 / 76%);
  -moz-box-shadow: 1px 2px 28px -13px rgb(163 157 157 / 76%);
}
.interest-card:hover {
  cursor: pointer;
  box-shadow: 1px 2px 28px -13px rgb(78 60 60 / 76%);
  -webkit-box-shadow: 1px 2px 28px -13px rgb(78 60 60 / 76%);
  -moz-box-shadow: 1px 2px 28px -13px rgb(78 60 60 / 76%);
}
.error-msg {
  padding-left: 8px;
  font-size: 12px;
  color: red;
}
</style>