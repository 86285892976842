import { DataService } from "../axios";


async function InterestsPost(data){
    return await DataService.post('hyphen/interest/',data)
}
async function ListInterests(){
    return await DataService.get('hyphen/interest/')
}

async function InterestsDelete(id){
    return await DataService.delete(`hyphen/interest/${id}/`)
}
async function InterestsUpdate(id,data){
    return await DataService.put(`hyphen/interest/${id}/`,data)
}


export{
    InterestsPost,
    ListInterests,
    InterestsDelete,
    InterestsUpdate
}